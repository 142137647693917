<template>
	<div class="pt-6 space-y-10">
		<PageTitle>
			Client Report (Beta)
		</PageTitle>

		<div class="flex items-center justify-between">
			<div class="flex space-x-4">
				<DateRangePicker
					v-model:from="fromDate"
					v-model:to="toDate"
				/>
			</div>
		</div>

		<div class="space-y-6">
			<p class="text-2xl font-semibold">
				Log Historie
			</p>
			<div class="grid grid-cols-12 gap-4">
				<div class="bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-full">
					<div class="border-b-2 border-primary-300 pb-4 flex justify-between items-center">
						<div class="text-sm opacity-95 pl-4">
							<span class="font-bold">Ergebnisse: </span>
							<span>{{ filteredBeatsTableRows.length }} Logs</span>,
							<span class="font-bold">Gesamtdauer: </span>
							<span>{{ filteredBeatsTableRowsTotalTime }}h</span>
						</div>
						<div class="w-96 ml-auto">
							<AppInput
								v-model="logSearch"
								name="logSearch"
								placeholder="Beats durchsuchen..."
								class="text-sm"
							/>
						</div>
					</div>
					<TableBeatsWithUser
						ref="beatsTable"
						:beats="currentStatsPeriodBeats"
						class="max-h-[700px] overflow-auto has-custom-scrollbar"
					/>
				</div>
			</div>
		</div>

		<div class="space-y-6">
			<div class="flex items-center justify-between bg-primary-400 rounded-2xl p-10 shadow-widget text-center">
				<p class="text-2xl font-semibold">
					High-Fun vs Low-Fun
				</p>
				<div class="flex space-x-16">
					<div class="flex space-x-6 items-center">
						<SatisfactionHappy class="w-16 opacity-50" />
						<span class="text-2xl">
							{{ highSatisfactionLoggedTimeInPercent }}%
						</span>
					</div>
					<div class="flex space-x-6 items-center">
						<SatisfactionSad class="w-16 opacity-50" />
						<span class="text-2xl">
							{{ lowSatisfactionLoggedTimeInPercent }}%
						</span>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-12 gap-4">
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-6">
					<TableTopic
						:data="highSatisfactionTableEntries"
						class="w-full max-h-[400px] overflow-auto has-custom-scrollbar"
					/>
				</div>
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-6">
					<TableTopic
						:data="lowSatisfactionTableEntries"
						class="w-full max-h-[400px] overflow-auto has-custom-scrollbar"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import PageTitle from '@/components/PageTitle.vue';
import {
	computed, ref, watch,
} from 'vue';
import { msToDisplayTime } from '@/shared/times';
import { DateTime } from 'luxon';
import { useSatisfactionStats } from '@/composables/useSatisfactionStats';
import {
	getTotalTimeInMs,
	groupBeatsByTopicResolved,
	createBeatFromSupabase,
} from '@/models/Beats';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useActivitiesStore } from '@/store/activities';
import DateRangePicker from '@/components/DateRangePicker.vue';
import AppInput from '@/components/AppInput.vue';
import { useFieldSearch } from '@/composables/useFieldSearch';
import TableBeatsWithUser from '@/components/TableBeatsWithUser.vue';
import TableTopic from '@/components/TableTopic.vue';
import { BeatTableEntry } from '@/models/BeatTableEntry';
import { useAppProgressLoader } from '@sunlabde/vuelab';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { supabase } from '@/lib/supabase';
import SatisfactionSad from '../assets/satisfaction-sad.svg?component';
import SatisfactionHappy from '../assets/satisfaction-happy.svg?component';

/**
 * IMPORTANT: This view is work in progress, we just already pushed in
 * to main to work with it
 */

const fromDate = ref(DateTime.now().minus({ days: 30 }).startOf('day'));
const toDate = ref(DateTime.now().endOf('day'));

const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const { data: beatsData, isFetching: isFetchingBeats } = useQuery({
	queryKey: ['client-beats', { fromDate, toDate }],
	queryFn: async () => {
		const { data: beatsDataRaw } = await supabase
			.from('beats')
			.select('*, users!inner(*)')
			.eq('client_id', '1ee31195-9827-6f10-8e09-77f7c88c995c')
			.eq('archived', false)
			.order('timestamp', { ascending: false })
			.gt('timestamp', fromDate.value.startOf('day'))
			.lt('timestamp', toDate.value.endOf('day'));

		return beatsDataRaw?.map((data) => ({ ...createBeatFromSupabase(data), userName: data.users.name })) ?? [];
	},
	placeholderData: keepPreviousData,
	refetchOnWindowFocus: true,
});

const isFetching = computed(() => isFetchingBeats.value);

const appPogressLoader = useAppProgressLoader();

watch(isFetching, (_isLoading) => {
	if (_isLoading) {
		appPogressLoader.start();
	} else {
		appPogressLoader.finishAll();
	}
});

const currentStatsPeriodBeats = computed(() => beatsData.value ?? []);

const {
	value: logSearch, fields, unmatchedInput,
} = useFieldSearch({ debounce: 500 });

const beatsTable = ref<InstanceType<typeof TableBeatsWithUser>>();

watch(fields, (newFields) => {
	beatsTable.value?.table.setColumnFilters(newFields);
});

watch(unmatchedInput, (input) => {
	beatsTable.value?.table.setGlobalFilter(input);
});

const filteredBeatsTableRows = computed(() => beatsTable.value?.table.getRowModel().rows ?? []);

const filteredBeatsTableRowsTotalTime = computed(() => msToDisplayTime(filteredBeatsTableRows.value.reduce((a, b) => a + b.original.timeIntervalInMs, 0)));

const {
	beatsWithHighSatisfaction,
	beatsWithLowSatisfaction,
	highSatisfactionLoggedTimeInPercent,
	lowSatisfactionLoggedTimeInPercent,
} = useSatisfactionStats(currentStatsPeriodBeats);

const highSatisfactionTableEntries = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByTopicResolved({
		beats: beatsWithHighSatisfaction.value,
		activities: activitiesStore.activities,
		clients: clientsStore.clients,
		projects: projectsStore.projects,
	});

	return Object.entries(group).map<BeatTableEntry>(([topic, beats]) => ({
		beats,
		id: topic,
		logCount: beats.length,
		name: topic,
		totalDurationInMs: getTotalTimeInMs(beats),
	}));
});

const lowSatisfactionTableEntries = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByTopicResolved({
		beats: beatsWithLowSatisfaction.value,
		activities: activitiesStore.activities,
		clients: clientsStore.clients,
		projects: projectsStore.projects,
	});

	return Object.entries(group).map<BeatTableEntry>(([topic, beats]) => ({
		beats,
		id: topic,
		logCount: beats.length,
		name: topic,
		totalDurationInMs: getTotalTimeInMs(beats),
	}));
});
</script>
