import { RouteRecordRaw } from 'vue-router';
import { DateTime } from 'luxon';
import { useAuthentication } from '@/store/auth';
import ClientView from '@/views/ClientView.vue';
import ProjectView from '@/views/ProjectView.vue';
import CreateActivityView from '@/views/CreateActivityView.vue';
import CreateClientView from '@/views/CreateClientView.vue';
import ClientGeneralView from '@/views/ClientGeneralView.vue';
import ProjectGeneralView from '@/views/ProjectGeneralView.vue';
import QuotasView from '@/views/QuotasView.vue';
import CreateProjectView from '@/views/CreateProjectView.vue';
import TeamReportView from '@/views/TeamReportView.vue';
import ClientReportView from '@/views/ClientReportView.vue';
import AdminInsightsView from '@/views/AdminInsightsView.vue';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { supabase } from '@/lib/supabase';
import { useMainStore } from '@/store/main';
import TopicOverviewView from '@/views/TopicOverviewView.vue';
import Dashboard from '../views/DashboardView.vue';
import History from '../views/HistoryView.vue';
import Signup from '../views/SignupView.vue';
import Logout from '../views/LogoutView.vue';
import Version from '../views/VersionView.vue';
import DaySingleView from '../views/DaySingleView.vue';
import AdminView from '../views/AdminView.vue';
import QuotaReportView from '../views/QuotaReportView.vue';
import StreamView from '../views/StreamView.vue';
import PersonalReportView from '../views/PersonalReportView.vue';
import UserView from '../views/UserView.vue';
import ManageQuotaListsView from '../views/ManageQuotaListsView.vue';

export const routes: RouteRecordRaw[] = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/topic-overview',
		name: 'TopicOverview',
		component: TopicOverviewView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/stream',
		name: 'Stream',
		component: StreamView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/report',
		name: 'PersonalReport',
		component: PersonalReportView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/team-report',
		name: 'TeamReport',
		component: TeamReportView,
		meta: {
			requiresAuth: true,
			requiresAdmin: true,
		},
	},
	{
		path: '/client-report',
		name: 'ClientReport',
		component: ClientReportView,
		meta: {
			requiresAuth: true,
			requiresAdmin: true,
		},
	},
	{
		path: '/quota-report',
		name: 'QuotaReportRoot',
		component: QuotaReportView,
		meta: {
			requiresAuth: true,
		},
		props: true,
		beforeEnter: (async (to, from, next) => {
			const { user } = useMainStore();

			if (!user) return next({ name: 'Signup' });

			const { data } = await supabase
				.from('users_access_in_quota_list')
				.select('*')
				.eq('user_id', user.id);

			const quotaLists = data ?? [];

			if (!quotaLists.length) {
				return next({
					name: 'Dashboard',
				});
			}

			return next({
				name: 'QuotaReport',
				params: {
					quotaListId: quotaLists[0].quota_list_id,
				},
			});
		}),
	},
	{
		path: '/quota-report/:quotaListId',
		name: 'QuotaReport',
		component: QuotaReportView,
		meta: {
			requiresAuth: true,
		},
		props: true,
		beforeEnter: (async (to, from, next) => {
			const { user } = useMainStore();

			if (!user) return next({ name: 'Signup' });

			const { data } = await supabase
				.from('users_access_in_quota_list')
				.select('*')
				.eq('user_id', user.id);

			const quotaLists = data ?? [];

			if (!quotaLists.length) {
				return next({
					name: 'Dashboard',
				});
			}

			if (quotaLists[0].quota_list_id !== to.params.quotaListId) {
				return next({
					name: 'Dashboard',
				});
			}

			return next();
		}),
	},
	{
		path: '/calendar',
		name: 'History',
		component: History,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/calendar/day/:day',
		name: 'DaySingle',
		component: DaySingleView,
		meta: {
			requiresAuth: true,
		},
		props(route) {
			const dayParam = Array.isArray(route.params.day) ? route.params.day[0] : route.params.day;
			const date = DateTime.fromISO(dayParam, { zone: 'utc' });

			return {
				day: date,
			};
		},
		beforeEnter(to, from, next) {
			const dayParam = Array.isArray(to.params.day) ? to.params.day[0] : to.params.day;
			const date = DateTime.fromISO(dayParam);

			if (!date.isValid) {
				return next({ name: 'Dashboard' });
			}

			return next();
		},
	},
	{
		path: '/signup',
		name: 'Signup',
		component: Signup,
		meta: {
			layout: 'blank',
		},
		beforeEnter(to, from, next) {
			const { isAuthenticated } = useAuthentication();

			if (isAuthenticated) {
				next({ name: 'Dashboard' });
				return;
			}

			next();
		},
	},
	{
		path: '/version',
		name: 'Version',
		component: Version,
		meta: {
			layout: 'blank',
		},
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/admin',
		name: 'Admin',
		component: AdminView,
		meta: {
			requiresAuth: true,
			requiresAdmin: true,
		},
		children: [
			{
				path: 'client/new',
				name: 'CreateClient',
				component: CreateClientView,
				props: true,
			},
			{
				path: 'client/:clientId',
				name: 'Client',
				component: ClientView,
				props: true,
				redirect: { name: 'ClientGeneral' },
				children: [
					{
						path: '',
						name: 'ClientGeneral',
						component: ClientGeneralView,
						props: true,
					},
					{
						path: 'quotas',
						name: 'ClientQuotas',
						component: QuotasView,
						props: true,
					},
				],
			},
			{
				path: 'client/:clientId/new-project',
				name: 'CreateClientProject',
				component: CreateProjectView,
				props: true,
			},
			{
				path: 'project/:projectId',
				name: 'Project',
				component: ProjectView,
				props: true,
				redirect: { name: 'ProjectGeneral' },
				children: [
					{
						path: '',
						name: 'ProjectGeneral',
						component: ProjectGeneralView,
						props: true,
					},
					{
						path: 'quotas',
						name: 'ProjectQuotas',
						component: QuotasView,
						props: true,
					},
				],
			},
			{
				path: 'activity/new',
				name: 'CreateActivity',
				component: CreateActivityView,
				props: true,
			},
		],
	},
	{
		path: '/admin-insights',
		name: 'AdminInsights',
		component: AdminInsightsView,
		meta: {
			requiresAuth: true,
			requiresAdmin: true,
		},
	},
	{
		path: '/manage-quota-lists',
		name: 'ManageQuotaLists',
		component: ManageQuotaListsView,
		meta: {
			requiresAuth: true,
			requiresAdmin: true,
		},
	},
	...(isFeatureFlagEnabled(FeatureFlag.LOG_MISSING_NOTIFICATION)
		? [{
			path: '/user',
			name: 'User',
			component: UserView,
			meta: {
				requiresAuth: true,
			},
		}] : []),
	{
		path: '/:pathMatch(.*)*',
		redirect: { name: 'Dashboard' },
	},
];
