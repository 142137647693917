<template>
	<AppTable
		:table="table"
		class="w-full"
	/>
</template>

<script lang="ts" setup>
import {
	FilterFnOption,
	createColumnHelper,
	getCoreRowModel, getFilteredRowModel, getSortedRowModel, useVueTable,
} from '@tanstack/vue-table';
import { PropType, computed, h } from 'vue';
import { Beat, BeatResolved, resolveBeat } from '@/models/Beats';
import { msToDisplayTime } from '@/shared/times';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useActivitiesStore } from '@/store/activities';
import { rankItem } from '@tanstack/match-sorter-utils';
import AppTable from './AppTable.vue';
import InternalBadge from './InternalBadge.vue';

const props = defineProps({
	beats: {
		type: Array as PropType<Beat[]>,
		default: () => ([]),
	},
});

const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const resolvedBeats = computed(() => props.beats.map((beat) => resolveBeat({
	beat,
	activities: activitiesStore.activities,
	clients: clientsStore.allClients,
	projects: projectsStore.allProjects,
})));

const columnHelper = createColumnHelper<BeatResolved>();

const internalClientIds = computed(() => clientsStore.internalClients.map((client) => client.id));

const columns = [
	// @ts-expect-error – Testing Purposes
	columnHelper.accessor('userName', {
		id: 'userName',
		header: 'User',
	}),
	columnHelper.accessor('client', {
		id: 'client',
		header: 'Client',
		cell: (context) => h(
			'div',
			[
				context.getValue(),
				internalClientIds.value.includes(context.row.original.clientId)
					? h(InternalBadge)
					: '',
			],
		),
	}),
	columnHelper.accessor('project', {
		id: 'project',
		header: 'Projekt',
	}),
	columnHelper.accessor('activity', {
		id: 'activity',
		header: 'Aktivität',
	}),
	columnHelper.accessor('note', {
		header: 'Notiz',
		enableSorting: false,
	}),
	columnHelper.accessor('timestamp', {
		header: 'Datum',
		size: 0,
		cell: (context) => context.getValue().toLocaleString(),
	}),
	columnHelper.accessor('timeIntervalInMs', {
		header: 'Dauer',
		size: 0,
		cell: (context) => h(
			'div',
			{
				class: 'flex space-x-3 items-center',
			},
			msToDisplayTime(context.getValue()),
		),
	}),
];

const fuzzyFilter: FilterFnOption<Beat> = (row, columnId, value, addMeta) => {
	const itemRank = rankItem(row.getValue(columnId), value);
	addMeta(itemRank);
	return itemRank.passed;
};

const table = useVueTable({
	columns,
	initialState: {
		sorting: [{ id: 'timestamp', desc: true }],
	},
	get data() {
		return resolvedBeats.value;
	},
	getCoreRowModel: getCoreRowModel(),
	getFilteredRowModel: getFilteredRowModel(),
	getSortedRowModel: getSortedRowModel(),
	enableSortingRemoval: false,
	enableRowSelection: false,
	globalFilterFn: fuzzyFilter,
});

defineExpose({ table });
</script>
